import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAllByPatient(idPatient, date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'patient_id',
			'filters[0][operator]': '=',
			'filters[0][value]': idPatient,
			'filters[1][field]': 'date',
			'filters[1][operator]': '=',
			'filters[1][value]': date,
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)

		return data
	},
	async getAllByAreaDate(idArea, date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'area_id',
			'filters[0][operator]': '=',
			'filters[0][value]': idArea,
			'filters[1][field]': 'date',
			'filters[1][operator]': '=',
			'filters[1][value]': date,
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)

		return data
	},
	async getAll(date) {
		const params = {
			order: 'asc',
			order_by: 'date',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'date',
			'filters[0][operator]': '=',
			'filters[0][value]': date,
		}
		const { data } = await useJwt.get(API_ROUTES.order.get, params)

		return data
	},
	async getAllTypeDiet() {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 1000,
		}
		const { data } = await useJwt.get(API_ROUTES.type_diet.get, params)

		return data
	},
	save(id, data) {
		return useJwt.post(`${API_ROUTES.order.save}/${id}`, data)
	},
	delete(id) {
		return useJwt.delete(`${API_ROUTES.order.delete}/${id}`)
	},
	update(id, data) {
		return useJwt.post(`${API_ROUTES.order.update}/${id}`, data)
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.order.find}/${id}`)

		return data
	},
}
